import jwtDecode from "jwt-decode";
import { OktaCacheStorage } from "../generated/ed-api";

const BACKEND_STORAGE = "backend-access_token";
const OKTA_STORAGE = "okta-token-storage";

const auth = {
  getToken(): string {
    const backendStorage = this.getBackendStorage();
    return backendStorage.token || "";
  },

  getBackendStorage(): any {
    const backendStorageStr = localStorage.getItem(BACKEND_STORAGE);
    return backendStorageStr ? JSON.parse(backendStorageStr) : {};
  },

  getOktaTokenStorage(): OktaCacheStorage {
    const oktaStorage = localStorage.getItem(OKTA_STORAGE);
    return oktaStorage ? JSON.parse(oktaStorage) : {};
  },

  getOktaAccessToken(): string {
    const oktaJson: OktaCacheStorage = this.getOktaTokenStorage();
    if (!oktaJson || !oktaJson.accessToken || !oktaJson.accessToken.accessToken) {
      return "";
    }
    return oktaJson.accessToken.accessToken;
  },

  getOktaIdToken(): string {
    const oktaJson: OktaCacheStorage | null = this.getOktaTokenStorage();
    if (!oktaJson || !oktaJson.idToken || !oktaJson.idToken.idToken) {
      return "";
    }
    return oktaJson.idToken.idToken;
  },

  setBackendToken(token: string) {
    const tokenParts = token.split(".");
    const decodedBackendToken = JSON.parse(atob(tokenParts[1]));
    decodedBackendToken.token = token;
    localStorage.setItem(BACKEND_STORAGE, JSON.stringify(decodedBackendToken));
  },

  isBackendTokenExpired() {
    const backendStorage = this.getBackendStorage();
    return Object.keys(backendStorage).length === 0 || this.getBackendStorage().exp * 1000 < Date.now();
  },

  hasAnyProfile(profiles: any) {
    const userDetails: any = jwtDecode(this.getToken());
    return profiles.includes(userDetails.authorities);
  },

  hasProfile(profile: string) {
    const userDetails: any = jwtDecode(this.getToken());
    return userDetails.authorities === profile;
  },

  getProfile() {
    const userDetails: any = jwtDecode(this.getToken());
    return userDetails.authorities;
  },

  isActive(): boolean {
    const userDetails: any = jwtDecode(this.getToken());
    return userDetails.active;
  },

  getCountry(): string {
    const userDetails: any = jwtDecode(this.getToken());
    return userDetails.country;
  },

  isLoggedIn(): boolean {
    return this.getBackendStorage() && !this.isBackendTokenExpired() && this.isOktaAuthenticated();
  },

  isOktaAuthenticated(): boolean {
    if (!this.getOktaTokenStorage().idToken || !this.getOktaTokenStorage().accessToken) {
      return false;
    }
    return (
      this.getOktaIdToken() !== null &&
      this.getOktaAccessToken() !== "" &&
      this.getOktaTokenStorage().idToken.expiresAt * 1000 > Date.now() &&
      this.getOktaTokenStorage().idToken.expiresAt * 1000 > Date.now()
    );
  },

  isReseller(): boolean {
    const userDetails: any = jwtDecode(this.getToken());
    return userDetails.resellerCode;
  },

  isFrom(country: string): boolean {
    const userDetails: any = jwtDecode(this.getToken());
    return country === userDetails.country;
  },

  getExpirationDate(): number {
    const token: any = jwtDecode(this.getToken());
    return token.exp;
  },

  logout() {
    localStorage.removeItem(BACKEND_STORAGE);
    localStorage.removeItem(OKTA_STORAGE);
  },
};

export default auth;
