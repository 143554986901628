import React from "react";
import { BKG_DARK, BKG_GREY } from "../../theme";

const STYLE_BUTTON = {
  backgroundColor: BKG_DARK,
  color: "white",
  fontFamily: "CanalDemIItaliqueItalic",
  minHeight: 35,
  padding: "5px 10px",
  cursor: "pointer",
  outline: "none",
  border: "none",
  boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 6px",
  textAlign: "center" as "center",
};

const STYLE_BUTTON_DISABLED = {
  backgroundColor: BKG_GREY,
  cursor: "not-allowed",
};

const STYLE_BUTTON_HOVERED = {
  backgroundColor: "black",
};

const STYLE_LABEL_MANDATORY = {
  color: "red",
  fontFamily: "sans-serif",
  fontWeight: "bold" as "bold",
  fontSize: "1.3em",
  marginLeft: 5,
};

interface IProps {
  label: string | JSX.Element;
  disabled?: boolean;
  style?: React.CSSProperties;
  styleHovered?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>;
  required?: boolean;
  type?: "button" | "reset" | "submit";
  [key: string]: any;
}

interface IState {
  hovered: boolean;
}

class Button extends React.Component<IProps, IState> {
  public state = {
    hovered: false,
  };

  public handleMouseLeave = () => this.setState({ hovered: false });

  public handleMouseEnter = () => this.setState({ hovered: true });

  public render() {
    const { label, lock, required, style, styleHovered, onClick, type, ...otherProps } = this.props;
    let disabled = this.props.disabled;

    const { hovered } = this.state;

    const baseButtonStyle = {
      ...STYLE_BUTTON,
      ...(hovered ? STYLE_BUTTON_HOVERED : null),
      ...(disabled ? STYLE_BUTTON_DISABLED : null),
    };

    const customStyle = {
      ...style,
      ...(hovered && !disabled ? styleHovered : null),
    };

    return (
      <button
        style={{ ...baseButtonStyle, ...customStyle }}
        type={type || "button"}
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        {...otherProps}
      >
        {label}
        {required && <div style={{ ...STYLE_LABEL_MANDATORY, display: "inline-block" }}>*</div>}
      </button>
    );
  }
}

export default Button;
