import { apiPost, computeUrl } from "./helpers";

import {
  AuthenticationPayload,
  AuthResultDTO,
  OktaAuthenticationPayload
} from "../generated/ed-api";

export async function login(
  authBody: AuthenticationPayload
): Promise<AuthResultDTO> {
  return await apiPost("auth/local", authBody);
}

export async function oktaLogin(authBody: OktaAuthenticationPayload): Promise<AuthResultDTO> {
  return await apiPost("auth/local", authBody);
}

export function ssoLoginUrl(goback: string): string {
  return computeUrl(
    "/auth/canalp/login?goback=" +
      encodeURIComponent(location.origin + "/logged-in?frontgoback=" + goback)
  );
}

export async function ssoLogin(accessToken: string): Promise<AuthResultDTO> {
  return await apiPost(`auth/canalp/callback?sso_access_token=${accessToken}`);
}

