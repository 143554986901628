import React from "react";
import FontAwesome from "react-fontawesome";
import Hoverable from "../Hoverable";
import { ISnackMessage } from "./types";

const STYLE_MESSAGE: React.CSSProperties = {
  alignItems: "center",
  backgroundColor: "#555555CC",
  borderRadius: 20,
  margin: 5,
  display: "flex",
  padding: 10,
  textAlign: "center",
  width: 400,
  wordBreak: "break-word",
  zIndex: 999
};

interface IProps {
  message: ISnackMessage;
  dismiss: (id: string) => void;
}

class SnackbarMessage extends React.Component<IProps> {
  private timeout: any | null = null;

  public componentDidMount() {
    if (this.props.message.level === "error") {
      return;
    }
    this.timeout = setTimeout(() => {
      this.handleDismiss();
    }, 4000);
  }

  public componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  public handleDismiss = () => {
    const { dismiss, message } = this.props;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    dismiss(message.id);
  };

  public handleClickDismiss = () => {
    this.handleDismiss();
  };

  public render() {
    const { message } = this.props;

    return (
      <div
        style={{
          ...STYLE_MESSAGE,
          color: message.level === "error" ? "#ff8484" : "white"
        }}
      >
        <div style={{ flex: 1 }}>{message.text}</div>
        <Hoverable
          component={FontAwesome}
          props={{ name: "times", onClick: this.handleClickDismiss }}
          style={{ cursor: "pointer", marginLeft: 10 }}
          hoveredStyle={{ color: "red" }}
        />
      </div>
    );
  }
}

export default SnackbarMessage;
