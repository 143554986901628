import React, { Component } from "react";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

import { RouteComponentProps, withRouter } from "react-router-dom";

import Background from "../../commons/Background";
import ArrowButton from "../../commons/Buttons/ArrowButton";
import { withSnackbar } from "../../commons/Snackbar";
import { ISnackbar } from "../../commons/Snackbar/types";
import auth from "../auth";
import LoginHeader from "./LoginHeader";
import LoginWrapper from "./LoginWrapper";
import { withOktaAuth } from '@okta/okta-react';

type ModalType = "reset" | "inactive" | "expired" | "locked";

interface IProps extends RouteComponentProps, IOktaContext {
  redirectUrl: string;
  onSuccessLogin: () => void;
  snackbar: ISnackbar;
}

interface IState {
  error: boolean;
  hasResellerCode: boolean;
  modal?: ModalType;
  unlockingDate?: Date;
}

class LoginPage extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.login = this.login.bind(this);

    const isNotActive = auth.isLoggedIn() && !auth.isActive();

    // Token inactive, coming from sso only
    // We need to logout before mounting, otherwise token is being sent with requests
    // for backgrounds and fail
    if (isNotActive) {
      auth.logout();
    }

    this.state = {
      error: false,
      hasResellerCode: false,
      modal: isNotActive ? "inactive" : undefined
    };
  }

  public async login() {
    this.props.oktaAuth.signInWithRedirect();
  }

  public render() {
    return (
      <Background>
        <LoginHeader />
        <LoginWrapper>
                <div
                  style={{
                    width: "100%",
                    marginTop: 15,
                    marginLeft: 15
                  }}
                >
                  <div style={{
                    width: "90%",
                    marginTop: 15,
                    marginBottom: 15
                  }}>Veuillez cliquer sur le bouton connexion afin d’accéder au nouveau module d'authentification forte OKTA</div>
                  <ArrowButton
                    label="Connexion"
                    onClick={this.login}
                    color="#FE0F3C"
                    style={{
                      marginBottom: 15
                    }}
                  />
                </div>
        </LoginWrapper>
      </Background>
    );
  }
}

export default withOktaAuth(withSnackbar(withRouter(LoginPage)));