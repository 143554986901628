enum FILTER_CATEGORIES {
  FRONT_MESSAGE_FILTERS = "FRONT_MESSAGE_FILTERS",
  FRONT_CONTRACT_FILTERS = "FRONT_CONTRACT_FILTERS",
  BACK_WORKSPACE_FILTERS = "BACK_WORKSPACE_FILTERS",
  BACK_USERS_FILTERS = "BACK_USERS_FILTERS",
  BACK_FORM_REQUEST_FILTERS = "BACK_FORM_REQUEST_FILTERS",
  BACK_CONTRACT_FILTERS = "BACK_CONTRACT_FILTERS"
}

interface ISearchFilters {
  get: () => any;
  save: (filters: object) => void;
}

function parseFiltersFromLocalstorage(category: FILTER_CATEGORIES): object {
  const filters = localStorage.getItem(category);
  return filters ? JSON.parse(filters) : {};
}

function saveFiltersToLocalstorage(
  category: FILTER_CATEGORIES,
  filters: object
) {
  localStorage.setItem(category, JSON.stringify(filters));
}

function buildFilterStorage(category: FILTER_CATEGORIES): ISearchFilters {
  return {
    get: () => parseFiltersFromLocalstorage(category),
    save: (filters: object) => saveFiltersToLocalstorage(category, filters)
  };
}

export const messageFilters = buildFilterStorage(
  FILTER_CATEGORIES.FRONT_MESSAGE_FILTERS
);

export const frontContractFilters = buildFilterStorage(
  FILTER_CATEGORIES.FRONT_CONTRACT_FILTERS
);

export const workspaceFilters = buildFilterStorage(
  FILTER_CATEGORIES.BACK_WORKSPACE_FILTERS
);

export const userFilters = buildFilterStorage(
  FILTER_CATEGORIES.BACK_USERS_FILTERS
);

export const formRequestFilters = buildFilterStorage(
  FILTER_CATEGORIES.BACK_FORM_REQUEST_FILTERS
);

export const backContractFilters = buildFilterStorage(
  FILTER_CATEGORIES.BACK_CONTRACT_FILTERS
);

export const clearFilters = () => {
  Object.keys(FILTER_CATEGORIES).forEach(filters => {
    localStorage.removeItem(filters);
  });
};
