// tslint:disable

import Country from "../../commons/country";

/**
* 
* @export
* @interface ActiveDTO
*/
export interface ActiveDTO {
    /**
    * 
    * @type {boolean}
    * @memberof ActiveDTO
    */
    active: boolean;
}

/**
* 
* @export
* @interface AddPageProfilingDTO
*/
export interface AddPageProfilingDTO {
    /**
    * 
    * @type {string}
    * @memberof AddPageProfilingDTO
    */
    pageId: string;
    /**
    * 
    * @type {string}
    * @memberof AddPageProfilingDTO
    */
    profile: AddPageProfilingDTO_ProfileEnum;
}

/**
* @export
* @enum {string}
*/
export enum AddPageProfilingDTO_ProfileEnum {
    ACCUEIL = <any> 'ACCUEIL',
    PACKS = <any> 'PACKS',
    OFFRESSPECIFIQUES = <any> 'OFFRES_SPECIFIQUES',
    OFFRESVENDEUR = <any> 'OFFRES_VENDEUR',
    CANALPRO = <any> 'CANALPRO',
    PROGRAMMESHEBDOMADAIRES = <any> 'PROGRAMMES_HEBDOMADAIRES',
    ACTUALITESNOUVEAUTES = <any> 'ACTUALITES_NOUVEAUTES',
    PROGRAMMEVIDEO = <any> 'PROGRAMME_VIDEO',
    ELEMENTSPLV = <any> 'ELEMENTS_PLV',
    BOUCLEVIDEO = <any> 'BOUCLE_VIDEO',
    ACTU = <any> 'ACTU',
    PLANSFREQUENCE = <any> 'PLANS_FREQUENCE',
    MODERECEPTION = <any> 'MODE_RECEPTION',
    MODEEMPLOI = <any> 'MODE_EMPLOI',
    INFOS = <any> 'INFOS',
    MESENGAGEMENTS = <any> 'MES_ENGAGEMENTS'
}

/**
* 
* @export
* @interface Address
*/
export interface Address {
    /**
    * 
    * @type {string}
    * @memberof Address
    */
    cityName: string;
    /**
    * 
    * @type {string}
    * @memberof Address
    */
    complement: string;
    /**
    * 
    * @type {string}
    * @memberof Address
    */
    postalCode: string;
    /**
    * 
    * @type {string}
    * @memberof Address
    */
    streetName: string;
    /**
    * 
    * @type {string}
    * @memberof Address
    */
    streetNumber: string;
    /**
    * 
    * @type {string}
    * @memberof Address
    */
    streetType: string;
}

/**
* 
* @export
* @interface AuthResultDTO
*/
export interface AuthResultDTO {
    /**
    * 
    * @type {string}
    * @memberof AuthResultDTO
    */
    credentialResult: AuthResultDTO_CredentialResultEnum;
    /**
    * 
    * @type {string}
    * @memberof AuthResultDTO
    */
    token: string;
    /**
    * 
    * @type {Date}
    * @memberof AuthResultDTO
    */
    unlockingDate?: Date;
}

/**
* @export
* @enum {string}
*/
export enum AuthResultDTO_CredentialResultEnum {
    AUTHENTICATED = <any> 'AUTHENTICATED',
    OKTA_TOKEN_INVALID = <any> 'OKTA_TOKEN_INVALID',
    USER_NOT_REGISTERED = <any> 'USER_NOT_REGISTERED' 
}

/**
* 
* @export
* @interface AuthenticationPayload
*/
export interface AuthenticationPayload {
    /**
    * 
    * @type {string}
    * @memberof AuthenticationPayload
    */
    password: string;
    /**
    * 
    * @type {string}
    * @memberof AuthenticationPayload
    */
    username: string;
}

export interface OktaAuthenticationPayload {
    oktaJwt: String;
}

export interface OktaCacheStorage {
    accessToken: {
      accessToken: string,
      authorizeUrl: string,
      claims: {},
      expiresAt: number
    };
  
    idToken: {
      idToken: string,
      authorizeUrl: string,
      expiresAt: number,
      clientId: string,
      issuer: string,
      scopes: string[]
  
  
    };
  }

/**
* 
* @export
* @interface Body
*/
export interface Body {
    /**
    * 
    * @type {Array<string>}
    * @memberof Body
    */
    readBy: Array<string>;
    /**
    * 
    * @type {any}
    * @memberof Body
    */
    state: any;
    /**
    * 
    * @type {Array<string>}
    * @memberof Body
    */
    statuses: Array<string>;
}

/**
* 
* @export
* @interface CharterDetail
*/
export interface CharterDetail {
    /**
    * 
    * @type {Array<CharterInstanceDetail>}
    * @memberof CharterDetail
    */
    charterInstances: Array<CharterInstanceDetail>;
    /**
    * 
    * @type {string}
    * @memberof CharterDetail
    */
    description: string;
    /**
    * 
    * @type {string}
    * @memberof CharterDetail
    */
    fileName: string;
    /**
    * 
    * @type {number}
    * @memberof CharterDetail
    */
    fileSize: number;
    /**
    * 
    * @type {GroupLightDTO}
    * @memberof CharterDetail
    */
    group: GroupLightDTO;
    /**
    * 
    * @type {string}
    * @memberof CharterDetail
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof CharterDetail
    */
    title: string;
}

/**
* 
* @export
* @interface CharterInstanceDTO
*/
export interface CharterInstanceDTO {
    /**
    * 
    * @type {boolean}
    * @memberof CharterInstanceDTO
    */
    accepted: boolean;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceDTO
    */
    charterId: string;
    /**
    * 
    * @type {Date}
    * @memberof CharterInstanceDTO
    */
    creationDate: Date;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceDTO
    */
    description: string;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceDTO
    */
    fileName: string;
    /**
    * 
    * @type {number}
    * @memberof CharterInstanceDTO
    */
    fileSize: number;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceDTO
    */
    id: string;
    /**
    * 
    * @type {Date}
    * @memberof CharterInstanceDTO
    */
    processingDate?: Date;
    /**
    * 
    * @type {boolean}
    * @memberof CharterInstanceDTO
    */
    read: boolean;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceDTO
    */
    title: string;
}

/**
* 
* @export
* @interface CharterInstanceDetail
*/
export interface CharterInstanceDetail {
    /**
    * 
    * @type {boolean}
    * @memberof CharterInstanceDetail
    */
    accepted: boolean;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceDetail
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceDetail
    */
    name: string;
    /**
    * 
    * @type {Date}
    * @memberof CharterInstanceDetail
    */
    processingDate: Date;
}

/**
* 
* @export
* @interface CharterInstanceSearchResult
*/
export interface CharterInstanceSearchResult {
    /**
    * 
    * @type {Array<CharterInstanceSearchResultItem>}
    * @memberof CharterInstanceSearchResult
    */
    hits: Array<CharterInstanceSearchResultItem>;
    /**
    * 
    * @type {number}
    * @memberof CharterInstanceSearchResult
    */
    total: number;
}

/**
* 
* @export
* @interface CharterInstanceSearchResultItem
*/
export interface CharterInstanceSearchResultItem {
    /**
    * 
    * @type {boolean}
    * @memberof CharterInstanceSearchResultItem
    */
    accepted: boolean;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceSearchResultItem
    */
    charterId: string;
    /**
    * 
    * @type {any}
    * @memberof CharterInstanceSearchResultItem
    */
    created: any;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceSearchResultItem
    */
    id: string;
    /**
    * 
    * @type {Date}
    * @memberof CharterInstanceSearchResultItem
    */
    lastTouched: Date;
    /**
    * 
    * @type {Date}
    * @memberof CharterInstanceSearchResultItem
    */
    processingDate: Date;
    /**
    * 
    * @type {boolean}
    * @memberof CharterInstanceSearchResultItem
    */
    read: boolean;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceSearchResultItem
    */
    reference: string;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceSearchResultItem
    */
    status: string;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceSearchResultItem
    */
    title: string;
    /**
    * 
    * @type {string}
    * @memberof CharterInstanceSearchResultItem
    */
    user: string;
}

/**
* 
* @export
* @interface CharterSearchResult
*/
export interface CharterSearchResult {
    /**
    * 
    * @type {Array<CharterSearchResultItem>}
    * @memberof CharterSearchResult
    */
    hits: Array<CharterSearchResultItem>;
    /**
    * 
    * @type {number}
    * @memberof CharterSearchResult
    */
    total: number;
}

/**
* 
* @export
* @interface CharterSearchResultItem
*/
export interface CharterSearchResultItem {
    /**
    * 
    * @type {Date}
    * @memberof CharterSearchResultItem
    */
    creationDate: Date;
    /**
    * 
    * @type {string}
    * @memberof CharterSearchResultItem
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof CharterSearchResultItem
    */
    reference: string;
    /**
    * 
    * @type {string}
    * @memberof CharterSearchResultItem
    */
    title: string;
}

/**
* 
* @export
* @interface Denormalization
*/
export interface Denormalization {
    /**
    * 
    * @type {Date}
    * @memberof Denormalization
    */
    closed: Date;
    /**
    * 
    * @type {User}
    * @memberof Denormalization
    */
    closedBy?: User;
    /**
    * 
    * @type {string}
    * @memberof Denormalization
    */
    comment: string;
    /**
    * 
    * @type {string}
    * @memberof Denormalization
    */
    displayableStatus: string;
    /**
    * 
    * @type {string}
    * @memberof Denormalization
    */
    numAbonne: string;
    /**
    * 
    * @type {Date}
    * @memberof Denormalization
    */
    qualified: Date;
    /**
    * 
    * @type {Array<string>}
    * @memberof Denormalization
    */
    readBy: Array<string>;
    /**
    * 
    * @type {Date}
    * @memberof Denormalization
    */
    restockQualified: Date;
    /**
    * 
    * @type {string}
    * @memberof Denormalization
    */
    status: string;
}

/**
* 
* @export
* @interface DiffusionListItem
*/
export interface DiffusionListItem {
    /**
    * 
    * @type {boolean}
    * @memberof DiffusionListItem
    */
    group: boolean;
    /**
    * 
    * @type {string}
    * @memberof DiffusionListItem
    */
    id: string;
}

/**
* 
* @export
* @interface EditEditorialProfilingDTO
*/
export interface EditEditorialProfilingDTO {
    /**
    * 
    * @type {Array<AddPageProfilingDTO>}
    * @memberof EditEditorialProfilingDTO
    */
    added: Array<AddPageProfilingDTO>;
    /**
    * 
    * @type {Array<string>}
    * @memberof EditEditorialProfilingDTO
    */
    removed: Array<string>;
}

/**
* 
* @export
* @interface EditGroupPagesDTO
*/
export interface EditGroupPagesDTO {
    /**
    * 
    * @type {string}
    * @memberof EditGroupPagesDTO
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof EditGroupPagesDTO
    */
    profile: EditGroupPagesDTO_ProfileEnum;
}

/**
* @export
* @enum {string}
*/
export enum EditGroupPagesDTO_ProfileEnum {
    ACCUEIL = <any> 'ACCUEIL',
    PACKS = <any> 'PACKS',
    OFFRESSPECIFIQUES = <any> 'OFFRES_SPECIFIQUES',
    OFFRESVENDEUR = <any> 'OFFRES_VENDEUR',
    CANALPRO = <any> 'CANALPRO',
    PROGRAMMESHEBDOMADAIRES = <any> 'PROGRAMMES_HEBDOMADAIRES',
    ACTUALITESNOUVEAUTES = <any> 'ACTUALITES_NOUVEAUTES',
    PROGRAMMEVIDEO = <any> 'PROGRAMME_VIDEO',
    ELEMENTSPLV = <any> 'ELEMENTS_PLV',
    BOUCLEVIDEO = <any> 'BOUCLE_VIDEO',
    ACTU = <any> 'ACTU',
    PLANSFREQUENCE = <any> 'PLANS_FREQUENCE',
    MODERECEPTION = <any> 'MODE_RECEPTION',
    MODEEMPLOI = <any> 'MODE_EMPLOI',
    INFOS = <any> 'INFOS',
    MESENGAGEMENTS = <any> 'MES_ENGAGEMENTS'
}

/**
* 
* @export
* @interface EditGroupsDTO
*/
export interface EditGroupsDTO {
    /**
    * 
    * @type {Array<string>}
    * @memberof EditGroupsDTO
    */
    added: Array<string>;
    /**
    * 
    * @type {Array<string>}
    * @memberof EditGroupsDTO
    */
    removed: Array<string>;
}

/**
* 
* @export
* @interface EditPageGroupsDTO
*/
export interface EditPageGroupsDTO {
    /**
    * 
    * @type {Array<string>}
    * @memberof EditPageGroupsDTO
    */
    added: Array<string>;
    /**
    * 
    * @type {Array<string>}
    * @memberof EditPageGroupsDTO
    */
    removed: Array<string>;
}

/**
* 
* @export
* @interface EditRoutes
*/
export interface EditRoutes {
    /**
    * 
    * @type {Array<string>}
    * @memberof EditRoutes
    */
    addedRoutes: Array<EditRoutes_AddedRoutesEnum>;
    /**
    * 
    * @type {boolean}
    * @memberof EditRoutes
    */
    distribRs: boolean;
    /**
    * 
    * @type {Array<string>}
    * @memberof EditRoutes
    */
    removedRoutes: Array<EditRoutes_RemovedRoutesEnum>;
}

/**
* @export
* @enum {string}
*/
export enum EditRoutes_AddedRoutesEnum {
    CHANGEMENTVECTEUR = <any> 'CHANGEMENTVECTEUR',
    TRANSFORMATIONMATERIEL = <any> 'TRANSFORMATIONMATERIEL',
    ECHANGESAV = <any> 'ECHANGESAV',
    AJOUTSECONDDECODEUR = <any> 'AJOUTSECONDDECODEUR',
    MODIFICATIONOFFRE = <any> 'MODIFICATIONOFFRE',
    DOSSIERABONNE = <any> 'DOSSIERABONNE',
    SOUSCRIPTION = <any> 'SOUSCRIPTION',
}

/**
* @export
* @enum {string}
*/
export enum EditRoutes_RemovedRoutesEnum {
    CHANGEMENTVECTEUR = <any> 'CHANGEMENTVECTEUR',
    TRANSFORMATIONMATERIEL = <any> 'TRANSFORMATIONMATERIEL',
    ECHANGESAV = <any> 'ECHANGESAV',
    AJOUTSECONDDECODEUR = <any> 'AJOUTSECONDDECODEUR',
    MODIFICATIONOFFRE = <any> 'MODIFICATIONOFFRE',
    DOSSIERABONNE = <any> 'DOSSIERABONNE',
    SOUSCRIPTION = <any> 'SOUSCRIPTION',
}

/**
* 
* @export
* @interface EditUserMemberDTO
*/
export interface EditUserMemberDTO {
    /**
    * 
    * @type {Array<string>}
    * @memberof EditUserMemberDTO
    */
    added: Array<string>;
    /**
    * 
    * @type {Array<string>}
    * @memberof EditUserMemberDTO
    */
    removed: Array<string>;
}

/**
* 
* @export
* @interface Event
*/
export interface Event {
    /**
    * 
    * @type {IEvent}
    * @memberof Event
    */
    content: IEvent;
    /**
    * 
    * @type {Date}
    * @memberof Event
    */
    received: Date;
    /**
    * 
    * @type {string}
    * @memberof Event
    */
    userId: string;
}

/**
* 
* @export
* @interface FormFile
*/
export interface FormFile {
    /**
    * 
    * @type {string}
    * @memberof FormFile
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof FormFile
    */
    mime: string;
    /**
    * 
    * @type {string}
    * @memberof FormFile
    */
    name: string;
    /**
    * 
    * @type {number}
    * @memberof FormFile
    */
    size: number;
}

/**
* 
* @export
* @interface FormFileUploadResult
*/
export interface FormFileUploadResult {
    /**
    * 
    * @type {string}
    * @memberof FormFileUploadResult
    */
    id: string;
}

/**
* 
* @export
* @interface FormHeaderAndBodyBody
*/
export interface FormHeaderAndBodyBody {
    /**
    * 
    * @type {Body}
    * @memberof FormHeaderAndBodyBody
    */
    body: Body;
    /**
    * 
    * @type {Array<Event>}
    * @memberof FormHeaderAndBodyBody
    */
    events?: Array<Event>;
    /**
    * 
    * @type {Array<FormFile>}
    * @memberof FormHeaderAndBodyBody
    */
    files: Array<FormFile>;
    /**
    * 
    * @type {Header}
    * @memberof FormHeaderAndBodyBody
    */
    header: Header;
}

/**
* 
* @export
* @interface FormId
*/
export interface FormId {
    /**
    * 
    * @type {string}
    * @memberof FormId
    */
    category?: string;
    /**
    * 
    * @type {string}
    * @memberof FormId
    */
    displayName: string;
    /**
    * 
    * @type {string}
    * @memberof FormId
    */
    id: string;
    /**
    * 
    * @type {Array<MessageStatus>}
    * @memberof FormId
    */
    statuses: Array<MessageStatus>;
}

/**
* 
* @export
* @interface FormSearchResult
*/
export interface FormSearchResult {
    /**
    * 
    * @type {Array<FormSearchResultItem>}
    * @memberof FormSearchResult
    */
    hits: Array<FormSearchResultItem>;
    /**
    * 
    * @type {number}
    * @memberof FormSearchResult
    */
    total: number;
}

/**
* 
* @export
* @interface FormSearchResultItem
*/
export interface FormSearchResultItem {
    /**
    * 
    * @type {Date}
    * @memberof FormSearchResultItem
    */
    created: Date;
    /**
    * 
    * @type {Denormalization}
    * @memberof FormSearchResultItem
    */
    denormalization: Denormalization;
    /**
    * 
    * @type {string}
    * @memberof FormSearchResultItem
    */
    displayName: string;
    /**
    * 
    * @type {string}
    * @memberof FormSearchResultItem
    */
    distributorId: string;
    /**
    * 
    * @type {string}
    * @memberof FormSearchResultItem
    */
    formId: string;
    /**
    * 
    * @type {string}
    * @memberof FormSearchResultItem
    */
    id: string;
    /**
    * 
    * @type {Date}
    * @memberof FormSearchResultItem
    */
    lastTouched: Date;
    /**
    * 
    * @type {Array<string>}
    * @memberof FormSearchResultItem
    */
    readBy: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof FormSearchResultItem
    */
    reference: string;
    /**
    * 
    * @type {number}
    * @memberof FormSearchResultItem
    */
    version: number;

    /**
    * 
    * @type {string}
    * @memberof FormSearchResultItem
    */
    companyName: string;
}

/**
* 
* @export
* @interface Group
*/
export interface Group {
    /**
    * 
    * @type {Array<string>}
    * @memberof Group
    */
    allowedForms: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof Group
    */
    description: string;
    /**
    * 
    * @type {boolean}
    * @memberof Group
    */
    distribRs: boolean;
    /**
    * 
    * @type {string}
    * @memberof Group
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof Group
    */
    name: string;
    /**
    * 
    * @type {Array<MasterEditPage>}
    * @memberof Group
    */
    pages: Array<MasterEditPage>;
    /**
    * 
    * @type {Array<string>}
    * @memberof Group
    */
    routes: Array<Group_RoutesEnum>;
    /**
    * 
    * @type {Array<User>}
    * @memberof Group
    */
    users: Array<User>;
}

/**
* @export
* @enum {string}
*/
export enum Group_RoutesEnum {
    CHANGEMENTVECTEUR = <any> 'CHANGEMENTVECTEUR',
    TRANSFORMATIONMATERIEL = <any> 'TRANSFORMATIONMATERIEL',
    ECHANGESAV = <any> 'ECHANGESAV',
    AJOUTSECONDDECODEUR = <any> 'AJOUTSECONDDECODEUR',
    MODIFICATIONOFFRE = <any> 'MODIFICATIONOFFRE',
    DOSSIERABONNE = <any> 'DOSSIERABONNE',
    SOUSCRIPTION = <any> 'SOUSCRIPTION',
}

/**
* 
* @export
* @interface GroupDTO
*/
export interface GroupDTO {
    /**
    * 
    * @type {Array<string>}
    * @memberof GroupDTO
    */
    allowedForms: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof GroupDTO
    */
    description: string;
    /**
    * 
    * @type {boolean}
    * @memberof GroupDTO
    */
    distribRs: boolean;
    /**
    * 
    * @type {string}
    * @memberof GroupDTO
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof GroupDTO
    */
    name: string;
    /**
    * 
    * @type {Array<ProfiledMasterEditPageDTO>}
    * @memberof GroupDTO
    */
    pages: Array<ProfiledMasterEditPageDTO>;
    /**
    * 
    * @type {Array<string>}
    * @memberof GroupDTO
    */
    routes: Array<GroupDTO_RoutesEnum>;
    /**
    * 
    * @type {Array<UserWithoutGroups>}
    * @memberof GroupDTO
    */
    users: Array<UserWithoutGroups>;
}

/**
* @export
* @enum {string}
*/
export enum GroupDTO_RoutesEnum {
    CHANGEMENTVECTEUR = <any> 'CHANGEMENTVECTEUR',
    TRANSFORMATIONMATERIEL = <any> 'TRANSFORMATIONMATERIEL',
    ECHANGESAV = <any> 'ECHANGESAV',
    AJOUTSECONDDECODEUR = <any> 'AJOUTSECONDDECODEUR',
    MODIFICATIONOFFRE = <any> 'MODIFICATIONOFFRE',
    DOSSIERABONNE = <any> 'DOSSIERABONNE',
    SOUSCRIPTION = <any> 'SOUSCRIPTION',
}

/**
* 
* @export
* @interface GroupId
*/
export interface GroupId {
    /**
    * 
    * @type {string}
    * @memberof GroupId
    */
    id: string;
}

/**
* 
* @export
* @interface GroupInfo
*/
export interface GroupInfo {
    /**
    * 
    * @type {string}
    * @memberof GroupInfo
    */
    description: string;
    /**
    * 
    * @type {string}
    * @memberof GroupInfo
    */
    name: string;
}

/**
* 
* @export
* @interface GroupLightDTO
*/
export interface GroupLightDTO {
    /**
    * 
    * @type {string}
    * @memberof GroupLightDTO
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof GroupLightDTO
    */
    name: string;
}

/**
* 
* @export
* @interface GroupPageSearchResult
*/
export interface GroupPageSearchResult {
    /**
    * 
    * @type {Array<GroupWithPageDTO>}
    * @memberof GroupPageSearchResult
    */
    hits: Array<GroupWithPageDTO>;
    /**
    * 
    * @type {number}
    * @memberof GroupPageSearchResult
    */
    total: number;
}

/**
* 
* @export
* @interface GroupSearchResult
*/
export interface GroupSearchResult {
    /**
    * 
    * @type {Array<GroupWithoutUsers>}
    * @memberof GroupSearchResult
    */
    hits: Array<GroupWithoutUsers>;
    /**
    * 
    * @type {number}
    * @memberof GroupSearchResult
    */
    total: number;
}

/**
* 
* @export
* @interface GroupWithPageDTO
*/
export interface GroupWithPageDTO {
    /**
    * 
    * @type {string}
    * @memberof GroupWithPageDTO
    */
    description: string;
    /**
    * 
    * @type {string}
    * @memberof GroupWithPageDTO
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof GroupWithPageDTO
    */
    name: string;
    /**
    * 
    * @type {string}
    * @memberof GroupWithPageDTO
    */
    pageId: string;
    /**
    * 
    * @type {string}
    * @memberof GroupWithPageDTO
    */
    pageName: string;
    /**
    * 
    * @type {string}
    * @memberof GroupWithPageDTO
    */
    pageProfile: GroupWithPageDTO_PageProfileEnum;
}

/**
* @export
* @enum {string}
*/
export enum GroupWithPageDTO_PageProfileEnum {
    ACCUEIL = <any> 'ACCUEIL',
    PACKS = <any> 'PACKS',
    OFFRESSPECIFIQUES = <any> 'OFFRES_SPECIFIQUES',
    OFFRESVENDEUR = <any> 'OFFRES_VENDEUR',
    CANALPRO = <any> 'CANALPRO',
    PROGRAMMESHEBDOMADAIRES = <any> 'PROGRAMMES_HEBDOMADAIRES',
    ACTUALITESNOUVEAUTES = <any> 'ACTUALITES_NOUVEAUTES',
    PROGRAMMEVIDEO = <any> 'PROGRAMME_VIDEO',
    ELEMENTSPLV = <any> 'ELEMENTS_PLV',
    BOUCLEVIDEO = <any> 'BOUCLE_VIDEO',
    ACTU = <any> 'ACTU',
    PLANSFREQUENCE = <any> 'PLANS_FREQUENCE',
    MODERECEPTION = <any> 'MODE_RECEPTION',
    MODEEMPLOI = <any> 'MODE_EMPLOI',
    INFOS = <any> 'INFOS',
    MESENGAGEMENTS = <any> 'MES_ENGAGEMENTS'
}

/**
* 
* @export
* @interface GroupWithoutUsers
*/
export interface GroupWithoutUsers {
    /**
    * 
    * @type {string}
    * @memberof GroupWithoutUsers
    */
    description: string;
    /**
    * 
    * @type {boolean}
    * @memberof GroupWithoutUsers
    */
    distribRs: boolean;
    /**
    * 
    * @type {string}
    * @memberof GroupWithoutUsers
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof GroupWithoutUsers
    */
    name: string;
}

/**
* 
* @export
* @interface Header
*/
export interface Header {
    /**
    * 
    * @type {Date}
    * @memberof Header
    */
    created: Date;
    /**
    * 
    * @type {string}
    * @memberof Header
    */
    formId: string;
    /**
    * 
    * @type {string}
    * @memberof Header
    */
    id: string;
}

/**
* 
* @export
* @interface IEvent
*/
export interface IEvent {
}

/**
* 
* @export
* @interface InputStream
*/
export interface InputStream {
}

/**
* 
* @export
* @interface IterableMasterEditPageDTO
*/
export interface IterableMasterEditPageDTO {
}

/**
* 
* @export
* @interface IterableMasterEditPageWithProfileDTO
*/
export interface IterableMasterEditPageWithProfileDTO {
}

/**
* 
* @export
* @interface IterableProfiledMasterEditPageDTO
*/
export interface IterableProfiledMasterEditPageDTO {
}

/**
* 
* @export
* @interface Link
*/
export interface Link {
    /**
    * 
    * @type {string}
    * @memberof Link
    */
    href: string;
    /**
    * 
    * @type {boolean}
    * @memberof Link
    */
    templated: boolean;
}

/**
* 
* @export
* @interface MapstringLink
*/
export interface MapstringLink {
    [key: string]: Link;

}

/**
* 
* @export
* @interface MasterEditPage
*/
export interface MasterEditPage {
    /**
    * 
    * @type {Array<Group>}
    * @memberof MasterEditPage
    */
    groups: Array<Group>;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPage
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPage
    */
    profile?: MasterEditPage_ProfileEnum;
}

/**
* @export
* @enum {string}
*/
export enum MasterEditPage_ProfileEnum {
    ACCUEIL = <any> 'ACCUEIL',
    PACKS = <any> 'PACKS',
    OFFRESSPECIFIQUES = <any> 'OFFRES_SPECIFIQUES',
    OFFRESVENDEUR = <any> 'OFFRES_VENDEUR',
    CANALPRO = <any> 'CANALPRO',
    PROGRAMMESHEBDOMADAIRES = <any> 'PROGRAMMES_HEBDOMADAIRES',
    ACTUALITESNOUVEAUTES = <any> 'ACTUALITES_NOUVEAUTES',
    PROGRAMMEVIDEO = <any> 'PROGRAMME_VIDEO',
    ELEMENTSPLV = <any> 'ELEMENTS_PLV',
    BOUCLEVIDEO = <any> 'BOUCLE_VIDEO',
    ACTU = <any> 'ACTU',
    PLANSFREQUENCE = <any> 'PLANS_FREQUENCE',
    MODERECEPTION = <any> 'MODE_RECEPTION',
    MODEEMPLOI = <any> 'MODE_EMPLOI',
    INFOS = <any> 'INFOS',
    MESENGAGEMENTS = <any> 'MES_ENGAGEMENTS'
}

/**
* 
* @export
* @interface MasterEditPageDTO
*/
export interface MasterEditPageDTO {
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageDTO
    */
    backgroundImage: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageDTO
    */
    content: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageDTO
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageDTO
    */
    loginPageImage: string;
    /**
    * 
    * @type {Array<string>}
    * @memberof MasterEditPageDTO
    */
    scripts: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageDTO
    */
    status: MasterEditPageDTO_StatusEnum;
    /**
    * 
    * @type {Array<string>}
    * @memberof MasterEditPageDTO
    */
    stylesheets: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageDTO
    */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum MasterEditPageDTO_StatusEnum {
    DRAFT = <any> 'DRAFT',
    ACTIVE = <any> 'ACTIVE'
}

/**
* 
* @export
* @interface MasterEditPageWithGroupsDTO
*/
export interface MasterEditPageWithGroupsDTO {
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithGroupsDTO
    */
    backgroundImage: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithGroupsDTO
    */
    content: string;
    /**
    * 
    * @type {Array<GroupWithPageDTO>}
    * @memberof MasterEditPageWithGroupsDTO
    */
    groups: Array<GroupWithPageDTO>;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithGroupsDTO
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithGroupsDTO
    */
    profile: MasterEditPageWithGroupsDTO_ProfileEnum;
    /**
    * 
    * @type {Array<string>}
    * @memberof MasterEditPageWithGroupsDTO
    */
    scripts: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithGroupsDTO
    */
    status: MasterEditPageWithGroupsDTO_StatusEnum;
    /**
    * 
    * @type {Array<string>}
    * @memberof MasterEditPageWithGroupsDTO
    */
    stylesheets: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithGroupsDTO
    */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum MasterEditPageWithGroupsDTO_ProfileEnum {
    ACCUEIL = <any> 'ACCUEIL',
    PACKS = <any> 'PACKS',
    OFFRESSPECIFIQUES = <any> 'OFFRES_SPECIFIQUES',
    OFFRESVENDEUR = <any> 'OFFRES_VENDEUR',
    CANALPRO = <any> 'CANALPRO',
    PROGRAMMESHEBDOMADAIRES = <any> 'PROGRAMMES_HEBDOMADAIRES',
    ACTUALITESNOUVEAUTES = <any> 'ACTUALITES_NOUVEAUTES',
    PROGRAMMEVIDEO = <any> 'PROGRAMME_VIDEO',
    ELEMENTSPLV = <any> 'ELEMENTS_PLV',
    BOUCLEVIDEO = <any> 'BOUCLE_VIDEO',
    ACTU = <any> 'ACTU',
    PLANSFREQUENCE = <any> 'PLANS_FREQUENCE',
    MODERECEPTION = <any> 'MODE_RECEPTION',
    MODEEMPLOI = <any> 'MODE_EMPLOI',
    INFOS = <any> 'INFOS',
    MESENGAGEMENTS = <any> 'MES_ENGAGEMENTS'
}

/**
* @export
* @enum {string}
*/
export enum MasterEditPageWithGroupsDTO_StatusEnum {
    DRAFT = <any> 'DRAFT',
    ACTIVE = <any> 'ACTIVE'
}

/**
* 
* @export
* @interface MasterEditPageWithProfileDTO
*/
export interface MasterEditPageWithProfileDTO {
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithProfileDTO
    */
    backgroundImage: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithProfileDTO
    */
    content: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithProfileDTO
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithProfileDTO
    */
    loginPageImage: string;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithProfileDTO
    */
    profile: MasterEditPageWithProfileDTO_ProfileEnum;
    /**
    * 
    * @type {Array<string>}
    * @memberof MasterEditPageWithProfileDTO
    */
    scripts: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithProfileDTO
    */
    status: MasterEditPageWithProfileDTO_StatusEnum;
    /**
    * 
    * @type {Array<string>}
    * @memberof MasterEditPageWithProfileDTO
    */
    stylesheets: Array<string>;
    /**
    * 
    * @type {string}
    * @memberof MasterEditPageWithProfileDTO
    */
    title: string;
}

/**
* @export
* @enum {string}
*/
export enum MasterEditPageWithProfileDTO_ProfileEnum {
    ACCUEIL = <any> 'ACCUEIL',
    PACKS = <any> 'PACKS',
    OFFRESSPECIFIQUES = <any> 'OFFRES_SPECIFIQUES',
    OFFRESVENDEUR = <any> 'OFFRES_VENDEUR',
    CANALPRO = <any> 'CANALPRO',
    PROGRAMMESHEBDOMADAIRES = <any> 'PROGRAMMES_HEBDOMADAIRES',
    ACTUALITESNOUVEAUTES = <any> 'ACTUALITES_NOUVEAUTES',
    PROGRAMMEVIDEO = <any> 'PROGRAMME_VIDEO',
    ELEMENTSPLV = <any> 'ELEMENTS_PLV',
    BOUCLEVIDEO = <any> 'BOUCLE_VIDEO',
    ACTU = <any> 'ACTU',
    PLANSFREQUENCE = <any> 'PLANS_FREQUENCE',
    MODERECEPTION = <any> 'MODE_RECEPTION',
    MODEEMPLOI = <any> 'MODE_EMPLOI',
    INFOS = <any> 'INFOS',
    MESENGAGEMENTS = <any> 'MES_ENGAGEMENTS'
}

/**
* @export
* @enum {string}
*/
export enum MasterEditPageWithProfileDTO_StatusEnum {
    DRAFT = <any> 'DRAFT',
    ACTIVE = <any> 'ACTIVE'
}

/**
* 
* @export
* @interface MessageSearchResult
*/
export interface MessageSearchResult {
    /**
    * 
    * @type {Array<MessageSearchResultItem>}
    * @memberof MessageSearchResult
    */
    hits: Array<MessageSearchResultItem>;
    /**
    * 
    * @type {number}
    * @memberof MessageSearchResult
    */
    total: number;
}

/**
* 
* @export
* @interface MessageSearchResultItem
*/
export interface MessageSearchResultItem {
    /**
    * 
    * @type {boolean}
    * @memberof MessageSearchResultItem
    */
    form: boolean;
    /**
    * 
    * @type {string}
    * @memberof MessageSearchResultItem
    */
    id: string;
    /**
    * 
    * @type {Date}
    * @memberof MessageSearchResultItem
    */
    lastTouched: Date;
    /**
    * 
    * @type {any}
    * @memberof MessageSearchResultItem
    */
    readBy: any;
    /**
    * 
    * @type {string}
    * @memberof MessageSearchResultItem
    */
    reference: string;
    /**
    * 
    * @type {any}
    * @memberof MessageSearchResultItem
    */
    status: any;
    /**
    * 
    * @type {string}
    * @memberof MessageSearchResultItem
    */
    title: string;

    /**
     * @type {string}
     * @memberof MessageSearchResultItem
     */
    subscriberNo: string;
}

/**
* 
* @export
* @interface MessageStatus
*/
export interface MessageStatus {
    /**
    * 
    * @type {string}
    * @memberof MessageStatus
    */
    displayName: string;
    /**
    * 
    * @type {string}
    * @memberof MessageStatus
    */
    id: string;
}

/**
* 
* @export
* @interface NewCharter
*/
export interface NewCharter {
    /**
    * 
    * @type {string}
    * @memberof NewCharter
    */
    description: string;
    /**
    * 
    * @type {Array<DiffusionListItem>}
    * @memberof NewCharter
    */
    diffusionList: Array<DiffusionListItem>;
    /**
    * 
    * @type {string}
    * @memberof NewCharter
    */
    fileId: string;
    /**
    * 
    * @type {string}
    * @memberof NewCharter
    */
    group: string;
    /**
    * 
    * @type {string}
    * @memberof NewCharter
    */
    title: string;
}

/**
* 
* @export
* @interface NewProfileDTO
*/
export interface NewProfileDTO {
    /**
    * 
    * @type {string}
    * @memberof NewProfileDTO
    */
    profile: string;
}

/**
* 
* @export
* @interface ProfiledMasterEditPageDTO
*/
export interface ProfiledMasterEditPageDTO {
    /**
    * 
    * @type {Array<GroupLightDTO>}
    * @memberof ProfiledMasterEditPageDTO
    */
    groups: Array<GroupLightDTO>;
    /**
    * 
    * @type {string}
    * @memberof ProfiledMasterEditPageDTO
    */
    id: string;
    /**
    * 
    * @type {string}
    * @memberof ProfiledMasterEditPageDTO
    */
    profile: ProfiledMasterEditPageDTO_ProfileEnum;
}

/**
* @export
* @enum {string}
*/
export enum ProfiledMasterEditPageDTO_ProfileEnum {
    ACCUEIL = <any> 'ACCUEIL',
    PACKS = <any> 'PACKS',
    OFFRESSPECIFIQUES = <any> 'OFFRES_SPECIFIQUES',
    OFFRESVENDEUR = <any> 'OFFRES_VENDEUR',
    CANALPRO = <any> 'CANALPRO',
    PROGRAMMESHEBDOMADAIRES = <any> 'PROGRAMMES_HEBDOMADAIRES',
    ACTUALITESNOUVEAUTES = <any> 'ACTUALITES_NOUVEAUTES',
    PROGRAMMEVIDEO = <any> 'PROGRAMME_VIDEO',
    ELEMENTSPLV = <any> 'ELEMENTS_PLV',
    BOUCLEVIDEO = <any> 'BOUCLE_VIDEO',
    ACTU = <any> 'ACTU',
    PLANSFREQUENCE = <any> 'PLANS_FREQUENCE',
    MODERECEPTION = <any> 'MODE_RECEPTION',
    MODEEMPLOI = <any> 'MODE_EMPLOI',
    INFOS = <any> 'INFOS',
    MESENGAGEMENTS = <any> 'MES_ENGAGEMENTS'
}

/**
* 
* @export
* @interface ResellerInfo
*/
export interface ResellerInfo {
    /**
    * 
    * @type {string}
    * @memberof ResellerInfo
    */
    resellerCode?: string;
    /**
    * 
    * @type {string}
    * @memberof ResellerInfo
    */
    resellerPassword?: string;
}

/**
* 
* @export
* @interface RouteUrl
*/
export interface RouteUrl {
    /**
    * 
    * @type {string}
    * @memberof RouteUrl
    */
    url: string;
}

/**
* 
* @export
* @interface RoutesUserDTO
*/
export interface RoutesUserDTO {
    /**
    * 
    * @type {string}
    * @memberof RoutesUserDTO
    */
    distributorCode: string;
    /**
    * 
    * @type {string}
    * @memberof RoutesUserDTO
    */
    resellerCode: string;
}

/**
* 
* @export
* @interface SigningResultDTO
*/
export interface SigningResultDTO {
    /**
    * 
    * @type {string}
    * @memberof SigningResultDTO
    */
    errorMsg: string;
    /**
    * 
    * @type {boolean}
    * @memberof SigningResultDTO
    */
    success: boolean;
    /**
    * 
    * @type {boolean}
    * @memberof SigningResultDTO
    */
    tryExhausted: boolean;
}

/**
* 
* @export
* @interface StartSigningCharterResultDTO
*/
export interface StartSigningCharterResultDTO {
    /**
    * 
    * @type {string}
    * @memberof StartSigningCharterResultDTO
    */
    externalRequestId: string;
}

/**
* 
* @export
* @interface User
*/
export interface User {
    /**
    * 
    * @type {boolean}
    * @memberof User
    */
    active: boolean;
    /**
    * 
    * @type {Address}
    * @memberof User
    */
    address: Address;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    category: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    civility: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    companyName: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    email: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    externalId: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    fax: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    firstName: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    groupement: string;
    /**
    * 
    * @type {Array<Group>}
    * @memberof User
    */
    groups: Array<Group>;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    landlinePhoneNumber: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    lastName: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    mobilePhoneNumber: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    organization: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    osirisAuthor: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    password: string;
    /**
    * 
    * @type {Date}
    * @memberof User
    */
    passwordExpiry: Date;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    profile?: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    region: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    resellerCode?: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    resellerPassword?: string;
    /**
    * 
    * @type {string}
    * @memberof User
    */
    secteur: string;
}

/**
* 
* @export
* @interface UserDto
*/
export interface UserDto {
    /**
    * 
    * @type {boolean}
    * @memberof UserDto
    */
    active: boolean;
    /**
    * 
    * @type {Address}
    * @memberof UserDto
    */
    address: Address;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    category: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    civility: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    companyName: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    email: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    externalId: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    fax: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    firstName: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    groupement: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    landlinePhoneNumber: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    lastName: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    mobilePhoneNumber: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    organization: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    osirisAuthor: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    profile: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    region: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    resellerCode: string;
    /**
    * 
    * @type {string}
    * @memberof UserDto
    */
    secteur: string;

    lastLogin: Date;
}

/**
* 
* @export
* @interface UserInfo
*/
export interface UserInfo {
    /**
    * 
    * @type {Address}
    * @memberof UserInfo
    */
    address: Address;
    /**
    * 
    * @type {string}
    * @memberof UserInfo
    */
    companyName: string;
    /**
    * 
    * @type {string}
    * @memberof UserInfo
    */
    email: string;
    /**
    * 
    * @type {string}
    * @memberof UserInfo
    */
    externalId: string;
    /**
    * 
    * @type {string}
    * @memberof UserInfo
    */
    firstName: string;
    /**
    * 
    * @type {string}
    * @memberof UserInfo
    */
    landlinePhoneNumber: string;
    /**
    * 
    * @type {string}
    * @memberof UserInfo
    */
    lastName: string;
    /**
    * 
    * @type {string}
    * @memberof UserInfo
    */
    mobilePhoneNumber: string;
    /**
    * 
    * @type {string}
    * @memberof UserInfo
    */
    resellerCode: string;
    /**
    * 
    * @type {Array<string>}
    * @memberof UserInfo
    */
    rights: Array<UserInfo_RightsEnum>;

     /**
    * 
    * @type {Array<string>}
    * @memberof UserInfo
    */
    groups: Array<String>;
    /**
     *
     * @type {Country}
     * @memberof UserInfo
     */
    country: Country;

}

/**
* @export
* @enum {string}
*/
export enum UserInfo_RightsEnum {
    ACCUEIL = <any> 'ACCUEIL',
    PACKS = <any> 'PACKS',
    OFFRESSPECIFIQUES = <any> 'OFFRES_SPECIFIQUES',
    OFFRESVENDEUR = <any> 'OFFRES_VENDEUR',
    CANALPRO = <any> 'CANALPRO',
    PROGRAMMESHEBDOMADAIRES = <any> 'PROGRAMMES_HEBDOMADAIRES',
    ACTUALITESNOUVEAUTES = <any> 'ACTUALITES_NOUVEAUTES',
    PROGRAMMEVIDEO = <any> 'PROGRAMME_VIDEO',
    ELEMENTSPLV = <any> 'ELEMENTS_PLV',
    BOUCLEVIDEO = <any> 'BOUCLE_VIDEO',
    ACTU = <any> 'ACTU',
    PLANSFREQUENCE = <any> 'PLANS_FREQUENCE',
    MODERECEPTION = <any> 'MODE_RECEPTION',
    MODEEMPLOI = <any> 'MODE_EMPLOI',
    INFOS = <any> 'INFOS',
    MESENGAGEMENTS = <any> 'MES_ENGAGEMENTS',
    EXPORTDARTY = <any> 'EXPORT_DARTY',
    WEBAGENDA = <any> 'WEB_AGENDA'
}

/**
* 
* @export
* @interface UserSearchResult
*/
export interface UserSearchResult {
    /**
    * 
    * @type {Array<UserWithoutGroups>}
    * @memberof UserSearchResult
    */
    hits: Array<UserWithoutGroups>;
    /**
    * 
    * @type {number}
    * @memberof UserSearchResult
    */
    total: number;
}

/**
* 
* @export
* @interface UserWithoutGroups
*/
export interface UserWithoutGroups {
    /**
    * 
    * @type {string}
    * @memberof UserWithoutGroups
    */
    companyName: string;
    /**
    * 
    * @type {string}
    * @memberof UserWithoutGroups
    */
    externalId: string;
    /**
    * 
    * @type {string}
    * @memberof UserWithoutGroups
    */
    firstName: string;
    /**
    * 
    * @type {string}
    * @memberof UserWithoutGroups
    */
    lastName: string;
}


export interface DistributorStockResult {

   /**
    * 
    * @type {Array<DistributorStocks>}
    * @memberof DistributorStockResult
    */
   data: DistributorStocks;

   /**
    * 
    * @type {string}
    * @memberof DistributorStocks
    */
   message: String;

   /**
    * 
    * @type {string}
    * @memberof DistributorStocks
    */
   returnCode: String;

   /**
    * 
    * @type {string}
    * @memberof DistributorStocks
    */
   severity: String;
}

export interface DistributorStocks {

    /**
    * 
    * @type {string}
    * @memberof DistributorStocks
    */
    distributorId: String;

    /**
    * 
    * @type {DistributorEquipment}
    * @memberof DistributorStocks
    */
   newEquipments: DistributorEquipment[];

   /**
    * 
    * @type {DistributorEquipment}
    * @memberof DistributorStocks
    */
   returnEquipments: DistributorEquipment[];

   /**
    * 
    * @type {Number}
    * @memberof DistributorStocks
    */
   totalNewEquipements: Number;

   /**
    * 
    * @type {Number}
    * @memberof DistributorStocks
    */
   totalReturnEquipements: Number;

      /**
    * 
    * @type {string}
    * @memberof DistributorStockResult
    */
   code: String;
}

export interface DistributorEquipment {

    /**
    * 
    * @type {string}
    * @memberof DistributorEquipment
    */
   type: String;

   /**
    * 
    * @type {string}
    * @memberof DistributorEquipment
    */
   label: String;

   /**
    * 
    * @type {number}
    * @memberof DistributorEquipment
    */
   quantity: number;

   
    /**
    * 
    * @type {Array<Equipment>}
    * @memberof DistributorEquipment
    */
   equipments: Array<Equipment>;
}

export interface Equipment {

    /**
    * 
    * @type {string}
    * @memberof Equipment
    */
   equipmentType: String;

   /**
    * 
    * @type {string}
    * @memberof Equipment
    */
   equipmentId: String;

   /**
    * 
    * @type {string}
    * @memberof Equipment
    */
   nuidId: number;

   /**
    * 
    * @type {number}
    * @memberof Equipment
    */
   icasnId: number;

   /**
    * 
    * @type {number}
    * @memberof Equipment
    */
   shipmentId: number;

   /**
    * 
    * @type {number}
    * @memberof Equipment
    */
   personId: number;

   /**
    * 
    * @type {Date}
    * @memberof Equipment
    */
   entryStockDate: Date;

   /**
    * 
    * @type {string}
    * @memberof Equipment
    */
   fabricantId: String;

   /**
    * 
    * @type {string}
    * @memberof Equipment
    */
   fabricantd: String;
}

export interface DistributorExpeditionResult {

    /**
     * 
     * @type {DistributorExpedition}
     * @memberof DistributorExpeditionResult
     */
    data: DistributorExpedition;

    /**
    * 
    * @type {string}
    * @memberof DistributorStocks
    */
    message: String;

    /**
        * 
        * @type {string}
        * @memberof DistributorStocks
        */
    returnCode: String;

    /**
        * 
        * @type {string}
        * @memberof DistributorStocks
        */
    severity: String;
}

export interface DistributorExpedition {

    /**
     * 
     * @type {String}
     * @memberof DistributorStockResult
     */
    distributorId: String;

    /**
     * 
     * @type {Shipment}
     * @memberof DistributorStockResult
     */
    shipments: Shipment;

    /**
     * 
     * @type {CollectionShipment}
     * @memberof DistributorStockResult
     */
    collectionShipments: CollectionShipment;

    /**
     * 
     * @type {String}
     * @memberof DistributorStockResult
     */
    code: String;
}

export interface Shipment {

    /**
     * 
     * @type {Number}
     * @memberof Shipment
     */
    shipmentId: Number;

    /**
     * 
     * @type {Date}
     * @memberof Shipment
     */
    transfertDate: Date;

    /**
     * 
     * @type {Number}
     * @memberof Shipment
     */
    deliveryDelay: Number;

    /**
     * 
     * @type {Array<Family>}
     * @memberof Shipment
     */
    families: Family[];
}

export interface Family {

    /**
     * 
     * @type {String}
     * @memberof Family
     */
    type: String;

    /**
     * 
     * @type {String}
     * @memberof Family
     */
    label: String;

    /**
     * 
     * @type {Number}
     * @memberof Family
     */
    quantity: Number;
} 

export interface CollectionShipment {

    /**
     * 
     * @type {Number}
     * @memberof CollectionShipment
     */
    shipmentId: Number;

    /**
     * 
     * @type {String}
     * @memberof CollectionShipment
     */
    transfertDate: Date;

    /**
     * 
     * @type {Number}
     * @memberof CollectionShipment
     */
    minPrevisionDate: Date;

    /**
     * 
     * @type {Number}
     * @memberof CollectionShipment
     */
    maxPrevisionDate: Date;
} 