import React from "react";
import { BKG_DARK } from "../../theme";

const STYLE_HEADER = {
  backgroundColor: "white",
  fontFamily: "CanalDemIItaliqueItalic",
  height: 100,
  fontSize: "2em",
  padding: 15,
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "flex-start",
  justifyContent: "space-between"
};

const STYLE_CANAL = {
  backgroundColor: BKG_DARK,
  color: "white",
  fontSize: "1em",
  padding: 5
};

const LoginHeader = () => (
  <div style={STYLE_HEADER}>
    <div style={STYLE_CANAL}>CANAL+</div>
    BIENVENUE SUR MON ESPACE CANAL
  </div>
);

export default LoginHeader;
