import React from "react";

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const SplitContent = ({ children, style }: IProps) => (
  <div style={{ display: "flex", ...style }}>{children}</div>
);

export const LeftContent = ({ children, style }: IProps) => (
  <div
    style={{
      paddingTop: 20,
      paddingRight: 20,
      width: "25%"
    }}
  >
    <div
      style={{
        height: "100%",
        padding: 15,
        backgroundColor: "white",
        boxSizing: "border-box",
        ...style
      }}
    >
      {children}
    </div>
  </div>
);

export const RightContent = ({ children, style }: IProps) => (
  <div style={{ flex: 1, backgroundColor: "white", ...style }}>{children}</div>
);
