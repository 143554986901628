import moment from "moment";
import urljoin from "url-join";
import auth from "../Authenticator/auth";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

let hostname: string = "";

async function fetchOrFail(url: string, options: RequestInit): Promise<any> {
  const res = await fetch(url, options);
  if (!res.ok) {
    const error: any = new Error(`${res.status} code when requesting ${res.url}`);

    error.status = res.status;
    try {
      error.json = await res.json();
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.warn("Non-2xx response received without a JSON body");
    }
    throw error;
  }

  return res;
}

async function fetchJson(url: string, method: HttpMethod = "GET", body?: any, json: boolean = true): Promise<any> {
  const headers = new Headers();

  if (json) {
    headers.set("Content-Type", "application/json;charset=UTF-8");
  }
  if (auth.isLoggedIn()) {
    headers.set("Authorization", auth.getToken());
  }
  if (auth.isOktaAuthenticated()) {
    headers.set("Okta-Token", auth.getOktaAccessToken());
  }

  const options: RequestInit = {
    headers,
    method,
    body,
  };

  if (body) {
    if (body instanceof FormData) {
      options.body = body;
    } else {
      options.body = JSON.stringify(body);
    }
  }

  const res = await fetchOrFail(urljoin(hostname, url), options);
  const contentType = res.headers.get("Content-Type");
  if (!contentType || contentType.indexOf("application/json") === -1) {
    return null;
  }

  return res.json();
}

export async function apiGet(url: string): Promise<any> {
  return fetchJson(url, "GET");
}

export async function apiPost(url: string, body?: any): Promise<any> {
  return fetchJson(url, "POST", body);
}

export async function apiPostMultipart(url: string, body?: any): Promise<any> {
  return fetchJson(url, "POST", body, false);
}

export async function apiPutMultipart(url: string, body?: any): Promise<any> {
  return fetchJson(url, "PUT", body, false);
}

export async function apiPut(url: string, body?: any): Promise<any> {
  return fetchJson(url, "PUT", body);
}

export async function apiDelete(url: string, body?: any): Promise<any> {
  return fetchJson(url, "DELETE", body);
}

export function computeUrl(url: string): string {
  return urljoin(hostname, url);
}

export function computeUrlWithToken(url: string): string {
  let fullUrl = computeUrl(url);

  if (fullUrl.indexOf("?") > -1) {
    return (fullUrl += "&access_token=" + auth.getToken());
  }

  return (fullUrl += "?access_token=" + auth.getToken());
}

export function addOktaTokenToUrl(url: string): string {
  if (url.indexOf("?") > -1) {
    return (url += "&oktaToken=" + auth.getOktaAccessToken());
  }

  return (url += "?oktaToken=" + auth.getOktaAccessToken());
}

export function buildSearchParams(filter?: object) {
  const params = {};
  if (filter) {
    for (const field in filter) {
      if (filter[field]) {
        params[field] = filter[field];
      }
    }
  }
  return params;
}

export interface IAppConf {
  apiUrl: string;
  issuer: string;
  clientId: string;
  callbackUrl: string;
}

// Called on app start to fetch api url
// We use a timestamp query param to prevent browser from using cache
export async function fetchConf(): Promise<IAppConf> {
  const timestampQuery = "?t=" + moment();
  const conf: IAppConf = await fetchJson(urljoin(location.origin, "config.json") + timestampQuery);
  hostname = conf.apiUrl;
  return conf;
}
