import React from "react";

interface IProps<P> {
  component: React.ComponentClass<P> | string;
  props?: P;
  style?: React.CSSProperties;
  hoveredStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

interface IState {
  hovered: boolean;
}

class Hoverable<P> extends React.Component<IProps<P>, IState> {
  public state: IState = {
    hovered: false
  };

  public handleMouseLeave = () => this.setState({ hovered: false });

  public handleMouseEnter = () => this.setState({ hovered: true });

  public render() {
    const {
      component,
      props,
      children,
      style,
      hoveredStyle
    } = this.props;
    const { hovered } = this.state;

    let fullProps = {
      children,
      ...(props as any),
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
      style: {
        ...style,
        ...(hovered ? hoveredStyle : null)
      }
    };

    return React.createElement(component, fullProps);
  }
}

export default Hoverable;
