// Next two lines are polyfills needed for IE
// tslint:disable-next-line ordered-imports
import "date-input-polyfill/date-input-polyfill.dist";
import "react-app-polyfill/ie11";

// tslint:disable-next-line ordered-imports
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
