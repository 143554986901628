import React from "react";
import { BKG_DARK, BKG_LIGHTER_GREY } from "../theme";

const STYLE_CONTENT: React.CSSProperties = {
  color: BKG_DARK,
  backgroundColor: "white",
  minHeight: "898px"
};

const STYLE_TITLE: React.CSSProperties = {
  fontSize: "1.3em",
  color: BKG_LIGHTER_GREY,
  padding: "15px 20px",
  fontFamily: "CanalDemIItaliqueItalic",
  borderBottom: "1px solid grey",
  textTransform: "uppercase" as "uppercase"
};

interface IProps {
  children?: React.ReactNode;
  title?: string;
  style?: React.CSSProperties;
}

const Content: React.SFC<IProps> = ({ children, title, style }) => {
  return (
    <div style={{ ...STYLE_CONTENT, ...style }}>
      {title && <div style={STYLE_TITLE}>{title}</div>}
      {children}
    </div>
  );
};

export default Content;
