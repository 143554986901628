import React from "react";
import SnackbarMessage from "./SnackbarMessage";
import { ISnackbar, ISnackMessage, SnackMessageLevel } from "./types";

const STYLE_SNACKBAR: React.CSSProperties = {
  bottom: 0,
  boxSizing: "border-box",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "100%"
};

interface IProps {
  children: React.ReactNode;
}

interface IState {
  messages: ISnackMessage[];
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type ComponentConstructor<P> =
  | React.ComponentClass<P>
  | React.StatelessComponent<P>;

interface IWithSnackbar {
  snackbar: ISnackbar;
}

export function withSnackbar<
  P extends IWithSnackbar, // All props of component
  R = Omit<P, "snackbar"> // Props without pushMessage
>(Component: ComponentConstructor<P>): React.StatelessComponent<R> {
  return (props: R): JSX.Element => (
    <SnackBarContext.Consumer>
      {snackbar => {
        // @ts-ignore
        return <Component {...props} snackbar={snackbar} />;
      }}
    </SnackBarContext.Consumer>
  );
}

const providerInitialValue = (): ISnackbar => ({
  info: () => "",
  error: () => ""
});

const SnackBarContext = React.createContext(providerInitialValue());

class Snackbar extends React.Component<IProps, IState> {
  public state = {
    messages: []
  };

  public pushMessage = (level: SnackMessageLevel, text: string) => {
    this.setState(prevState => {
      const messages: ISnackMessage[] = [...prevState.messages];
      messages.unshift({
        id: text + level + new Date().getTime(),
        text,
        level
      });
      return { messages };
    });
  };

  public pushInfoMessage = (text: string) => {
    this.pushMessage("info", text);
  };

  public pushErrorMessage = (text: string) => {
    this.pushMessage("error", text);
  };

  public dismissMessage = (id: string) => {
    this.setState(prevState => {
      const messages: ISnackMessage[] = [...prevState.messages];
      const index = messages.findIndex(m => m.id === id);
      if (index >= 0) {
        messages.splice(index, 1);
      }
      return { messages };
    });
  };

  public render() {
    const { children } = this.props;
    const { messages } = this.state;

    const snackbar = {
      info: this.pushInfoMessage,
      error: this.pushErrorMessage
    };

    return (
      <SnackBarContext.Provider value={snackbar}>
        {children}
        {messages.length > 0 && (
          <div style={STYLE_SNACKBAR}>
            {messages.map((message: ISnackMessage) => (
              <SnackbarMessage
                key={message.id}
                dismiss={this.dismissMessage}
                message={message}
              />
            ))}
          </div>
        )}
      </SnackBarContext.Provider>
    );
  }
}

export default Snackbar;
