import React from "react";
import FontAwesome from "react-fontawesome";
import Button from "./Button";

interface IProps {
  color?: string;
  type?: "button" | "reset" | "submit";
  href?: string;
  label: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const ArrowButton: React.SFC<IProps> = ({ color, href, label, onClick, style, type }) => {
  const button = (
    <Button
      label={
        <div>
          {label}
          <FontAwesome
            name="arrow-right"
            style={{
              backgroundColor: color || "black",
              color: "white",
              padding: "4px 6px",
              textShadow: "none",
              marginLeft: 10,
            }}
          />
        </div>
      }
      type={type}
      onClick={onClick}
      style={{
        color: color || "black",
        fontStyle: "italic",
        fontSize: 16,
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: 0,
        fontFamily: undefined,
        ...style,
      }}
      styleHovered={{
        textDecoration: "underline",
        textDecorationColor: color || "black",
      }}
    />
  );
  if (href) {
    return <a href={href}>{button}</a>;
  }
  return button;
};

export default ArrowButton;
