import React from "react";
import { isBrowser } from "react-device-detect";
import { fectchBackground } from "../api/master-edit-api";
import { withSnackbar } from "./Snackbar";
import { ISnackbar } from "./Snackbar/types";

interface IProps {
  children?: React.ReactNode;
  snackbar: ISnackbar;
}

class Background extends React.Component<IProps> {
  public async componentDidMount() {
    const { snackbar } = this.props;
    if (isBrowser) {
      try {
        const urlWrapper = await fectchBackground();

        document.body.style.setProperty(
          "background",
          `url(${urlWrapper.url}) center top / cover no-repeat fixed`
        );
      } catch (e) {
        snackbar.error("Erreur lors de la récupération de l'image de fond");
      }
    }
  }

  public componentWillUnmount() {
    document.body.style.removeProperty("background");
  }

  public render() {
    if (isBrowser) {
      return (
        <div style={{ margin: "100px auto", width: "90%" }}>
          {this.props.children}
        </div>
      );
    }
    return <div style={{ width: "100%" }}>{this.props.children}</div>;
  }
}

export default withSnackbar(Background);
