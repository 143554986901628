import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchUserInfo } from "../api/profile-api";
import { withSnackbar } from "../commons/Snackbar";
import { ISnackbar } from "../commons/Snackbar/types";
import { AuthResultDTO_CredentialResultEnum, UserInfo } from "../generated/ed-api";
import auth from "./auth";
import LoginPage from "./LoginPage";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { withOktaAuth } from "@okta/okta-react";
import { oktaLogin } from "../api/auth-api";

/** We need a context to access user information across all app */
export interface IAuthContext {
  userInfo?: UserInfo;
  logout: () => void;
}

export const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type ComponentConstructor<P> = React.ComponentClass<P> | React.StatelessComponent<P>;

interface IWithUserInfo {
  userInfo: UserInfo;
}

export function withUserInfo<
  P extends IWithUserInfo, // All props of component
  R = Omit<P, "userInfo"> // Props without userInfo
>(Component: ComponentConstructor<P>): React.StatelessComponent<R> {
  return (props: R): JSX.Element => (
    <AuthContext.Consumer>
      {authContext => {
        // @ts-ignore
        return <Component {...props} userInfo={authContext!.userInfo!} />;
      }}
    </AuthContext.Consumer>
  );
}

/** convert minutes in milliseconds for setInterval */
const minutes = (m: number) => moment.duration(m, "minutes").asMilliseconds();

/** duration in minutes */
const EXPIRATION_LEEWAY = 1;
const EXPIRATION_CHECK = 5;

interface IProps extends RouteComponentProps, IOktaContext {
  children: JSX.Element;
  snackbar: ISnackbar;
}

interface IState {
  userInfo?: UserInfo;
  loading: boolean;
  redirectUrl: string;
}

class Authenticator extends React.Component<IProps, IState> {
  /** Unix timestamp in seconds */
  private tokenExpiration: number | null = null;
  /** Interval checking if token is going to expire soon (under 1H) to warn user */
  private tokenCheckInterval: any | null = null;
  /** Interval loging out the user if the token is about to expire */
  private logoutCheckInterval: any | null = null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      redirectUrl: location.pathname,
    };
  }

  public componentDidMount() {
    this.fetchUserInfo();
  }

  public componentWillUnmount() {
    this.cleanup();
  }

  public buildContext = (): IAuthContext => ({
    userInfo: this.state.userInfo,
    logout: this.logout,
  });

  public fetchUserInfo = async () => {
    const { history, location } = this.props;
    const { redirectUrl } = this.state;

    this.setState({ loading: true });
    let userInfo;

    if (auth.isOktaAuthenticated()) {
      try {
        const authResult = await oktaLogin({ oktaJwt: auth.getOktaAccessToken() });
        if (authResult.credentialResult === AuthResultDTO_CredentialResultEnum.AUTHENTICATED) {
          auth.setBackendToken(authResult.token);
        } else if (authResult.credentialResult === AuthResultDTO_CredentialResultEnum.OKTA_TOKEN_INVALID) {
          this.props.snackbar.info("Votre session a expiré, veuillez vous reconnecter.");
          auth.logout();
        } else {
          this.props.snackbar.error("L'authentification a échoué.");
        }
      } catch {
        this.props.snackbar.error("Une erreur est survenue lors de la récupération des données provenant d'Okta");
      }
    }

    if (auth.isLoggedIn() && auth.isActive()) {
      try {
        userInfo = await fetchUserInfo();
        history.push(redirectUrl);

        /** Starting intervals if user successfully logged in */
        this.startTokenCheck();
        this.startLogoutCheck();
      } catch (e) {
        auth.logout();
        history.push("/");
      }
    } else {
      history.push({ pathname: "/", search: location.search });
    }

    this.setState({ loading: false, userInfo });
  };

  public startTokenCheck = () => {
    const { snackbar } = this.props;

    this.tokenExpiration = auth.getExpirationDate();

    this.tokenCheckInterval = setInterval(() => {
      const minutesLeft = moment.unix(this.tokenExpiration!).diff(moment(), "minutes");

      if (minutesLeft < 60 && minutesLeft > 0) {
        snackbar.info(
          "Votre session arrive à expiration, vous serez déconnectés automatiquement dans " +
            (minutesLeft - EXPIRATION_LEEWAY) +
            " minutes"
        );
      }
    }, minutes(EXPIRATION_CHECK));
  };

  public startLogoutCheck = () => {
    const { snackbar } = this.props;

    this.logoutCheckInterval = setInterval(() => {
      const minutesLeft = moment.unix(this.tokenExpiration!).diff(moment(), "minutes");

      /** We log out user earlier to prevent user from firing requests that would fail  */
      if (minutesLeft < EXPIRATION_LEEWAY) {
        this.logout();
        snackbar.info("Votre session a expiré, veuillez vous reconnecter");
      }
    }, minutes(EXPIRATION_LEEWAY));
  };

  public logout = () => {
    const { history } = this.props;

    if (auth.isLoggedIn()) {
      auth.logout();
    }
    history.push("/");
    this.cleanup();
    this.setState({ userInfo: undefined, redirectUrl: "/" });
  };

  public cleanup = () => {
    clearInterval(this.tokenCheckInterval);
    clearInterval(this.logoutCheckInterval);
    this.tokenExpiration = null;
    this.logoutCheckInterval = null;
  };

  public render() {
    const { children } = this.props;
    const { loading, redirectUrl, userInfo } = this.state;

    if (loading) {
      return <div>Loading</div>;
    }

    if (!auth.isLoggedIn() || !auth.isActive() || !userInfo) {
      return <LoginPage redirectUrl={redirectUrl} onSuccessLogin={this.fetchUserInfo} />;
    }

    return <AuthContext.Provider value={this.buildContext()}>{children}</AuthContext.Provider>;
  }
}

export const AuthConsumer = AuthContext.Consumer;

export default withOktaAuth(withSnackbar(withRouter(Authenticator)));
