import {
  MasterEditPageDTO,
  ProfiledMasterEditPageDTO_ProfileEnum,
  RouteUrl
} from "../generated/ed-api";
import { apiGet } from "./helpers";

export async function fetchPageByProfile(
  profile: ProfiledMasterEditPageDTO_ProfileEnum
): Promise<MasterEditPageDTO[]> {
  return await apiGet(`api/masteredit/${profile}`);
}

export async function fectchBackground(): Promise<RouteUrl> {
  return await apiGet("masteredit/background");
}

export async function fetchLoginPageImage(): Promise<RouteUrl> {
  return await apiGet("masteredit/loginPage");
}
