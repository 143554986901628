import React from "react";
import { isBrowser } from "react-device-detect";
import { fetchLoginPageImage } from "../../api/master-edit-api";
import Content from "../../commons/Content";
import { withSnackbar } from "../../commons/Snackbar";
import { ISnackbar } from "../../commons/Snackbar/types";
import {
  LeftContent,
  RightContent,
  SplitContent
} from "../../commons/SplitContent";

interface IProps {
  children: React.ReactNode;
  snackbar: ISnackbar;
}

interface IState {
  imageUrl: string;
}

class LoginWrapper extends React.Component<IProps, IState> {
  public state: IState = {
    imageUrl: ""
  };

  public async componentDidMount() {
    const { snackbar } = this.props;

    try {
      const urlWrapper = await fetchLoginPageImage();
      this.setState({ imageUrl: urlWrapper.url });
    } catch (e) {
      snackbar.error("Erreur lors du chargement de l'image de login");
    }
  }

  public render() {
    const { children } = this.props;
    const { imageUrl } = this.state;

    if (isBrowser) {
      return (
        <SplitContent>
          <LeftContent>{children}</LeftContent>
          <RightContent style={{ padding: 15, paddingTop: 0 }}>
            <img
              style={{ maxWidth: "100%", maxHeight: "100%", width: "100%" }}
              src={imageUrl}
            />
          </RightContent>
        </SplitContent>
      );
    }

    return (
      <Content>
        <div style={{ padding: 20 }}>{children}</div>
        <div style={{ textAlign: "center" }}>
          <img style={{ width: "100%", marginTop: 30 }} src={imageUrl} />
        </div>
      </Content>
    );
  }
}

export default withSnackbar(LoginWrapper);
