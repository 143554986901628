import React from "react";
import { BrowserRouter, Route, Switch, RouteComponentProps, withRouter } from "react-router-dom";
import { fetchConf, IAppConf } from "./api/helpers";
import Authenticator from "./Authenticator";
import { clearFilters } from "./commons/filterSave";
import Snackbar from "./commons/Snackbar";
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

const lazyLoader = (
  LazyComponent: React.LazyExoticComponent<any>
): (() => JSX.Element) => () => (
  <React.Suspense fallback={<div>Loading ...</div>}>
    <LazyComponent />
  </React.Suspense>
);

const Backoffice = React.lazy(() =>
  import(/* webpackChunkName: "backoffice" */ "./Backoffice")
);
const DistributorSpace = React.lazy(() =>
  import(/* webpackChunkName: "distributor_space" */ "./DistributorSpace")
);

interface IState {
  conf?: IAppConf;
  error: boolean;
  oktaAuth?: OktaAuth;
}

class App extends React.Component<RouteComponentProps, IState> {

  restoreOriginalUri: (_oktaAuth: any, originalUri: any) => Promise<void>;
  
  public constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      error: false
    };
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
  }

  public async componentDidMount() {
    try {
      const conf = await fetchConf();
      this.setState(
        { 
          conf: conf, 
          oktaAuth: new OktaAuth({
            issuer: conf.issuer,
            clientId: conf.clientId,
            redirectUri: window.location.origin + conf.callbackUrl,
            devMode: process.env.NODE_ENV === 'development',
            pkce: true
          })
        });
    } catch (e) {
      console.log(e);
      this.setState({ error: true });
    }
    // Clear all search filters saved in localstorage
    clearFilters();
  }

  public onAuthResume() {
    this.props.history.push('/home');
  }

  public render() {
    const { conf, error, oktaAuth } = this.state;

    if (error) {
      return (
        <div style={{ color: "red" }}>
          Erreur lors du chargement de la configuration
        </div>
      );
    }

    if (!conf || !oktaAuth) {
      return <div>Loading...</div>;
    }

    return (
      <div style={{ fontFamily: "Open Sans, sans-serif" }}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri} >
            <Snackbar>
              <Switch>
                <Route path='/login/callback' render={ (props) => <LoginCallback {...props} onAuthResume={ this.onAuthResume } /> }/>
                  <Authenticator>
                    <Switch>
                      <SecureRoute path="/admin" component={lazyLoader(Backoffice)} />
                      <SecureRoute component={lazyLoader(DistributorSpace)} />
                    </Switch>
                  </Authenticator>
              </Switch>
            </Snackbar>
          </Security>
      </div>
    );
  }
}

const AppWithRouterAccess = withRouter(App);
export default class extends React.Component {
  render() {
    return (<BrowserRouter><AppWithRouterAccess/></BrowserRouter>)
  }
}
